/****************************************************

	Utilities - Images

****************************************************/
import { CmsImage } from "../models/common"

/****************************************************
	get alt text
****************************************************/
// export const getAltText = (
//   item?: MediaModel | CmsImage
// ): string | undefined => {
//   if (!item) return undefined
//   return item.hasOwnProperty("alt_text")
//     ? getImageAltText(item as MediaModel)
//     : getAssetAltText(item as CmsImage)
// }

// const getImageAltText = (item?: MediaModel): string | undefined => {
//   return item?.alt_text || getAssetAltText((item as MediaModel)?.image)
// }

export const getAssetAltText = (item?: CmsImage): string => {
  return item?.description || item?.title || ""
}

export const getImageSrc = (
  src: string = "",
  width: number,
  height: number,
  fit: string = "crop"
): string => {
  if (!src) {
    return ""
  }
  if (src.includes("https://images.contentstack.io")) {
    src = src.replace(
      "https://images.contentstack.io",
      "https://images.northernterritory.com"
    )
  }
  if (src.includes("https://assets.contentstack.io")) {
    src = src.replace(
      "https://assets.contentstack.io",
      "https://images.northernterritory.com"
    )
  }
  let url
  try {
    url = new URL(src)
  } catch (_) {
    return ""
  }

  if (src.includes("images.northernterritory.com")) {
    url.searchParams.set("width", width.toString())
    url.searchParams.set("height", height.toString())
    url.searchParams.set("fit", fit)
  } else if (src.includes("assets.atdw-online.com.au")) {
    url.searchParams.set("w", width.toString())
    url.searchParams.set("h", height.toString())
    url.searchParams.set("fit", fit)
    url.searchParams.set("auto", "enhance,compress")
  }
  return url.toString()
}
export {}
