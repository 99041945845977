import React from "react"

import PlacesSubMenu from "../../Nav/Desktop/PlacesSubMenu"
import ToDoSubMenu from "../../Nav/Desktop/ToDoSubMenu"
import PlanSubMenu from "../../Nav/Desktop/PlanSubMenu"
import TripSubMenu from "../../Nav/Desktop/TripSubMenu"
import { useArticles, useItineraries, useLinks, usePages } from "../../../hooks"
import useMenus from "../../../hooks/useMenus"
import { resolveMenuLinks } from "../../../utils/utils_links"
import { NavMenuModel } from "../../../models/nav"

interface Props {
  location: Location
  locale: string
}

const defaultProps = {}

const PrimaryMenu: React.FC<Props> = props => {
  const links = useLinks(props.locale)
  const pages = usePages(props.locale)
  const articles = useArticles(props.locale)
  const itineraries = useItineraries(props.locale)
  const menus = useMenus(props.locale)
  const menu: NavMenuModel = menus.find(
    (x: NavMenuModel) =>
      x.code_id === "menu-top-level" &&
      x.publish_details?.locale === props.locale
  )

  const menuLinkData = {
    pages: pages.filter((x: any) => x.publish_details?.locale === props.locale),
    links: links.filter((x: any) => x.publish_details?.locale === props.locale),
    articles: articles.filter(
      (x: any) => x.publish_details?.locale === props.locale
    ),
    itineraries: itineraries.filter(
      (x: any) => x.publish_details?.locale === props.locale
    ),
    menus: menus.filter((x: any) => x.publish_details?.locale === props.locale),
    locale: props.locale,
  }

  const menuItems = resolveMenuLinks({
    menu,
    ...menuLinkData,
  })
  if (!menuItems) return null
  const mainMenu = [
    {
      label: menuItems[0].label,
      subMenu: PlacesSubMenu,
    },
    {
      label: menuItems[1].label,
      subMenu: ToDoSubMenu,
    },
    {
      label: menuItems[2].label,
      subMenu: PlanSubMenu,
    },
    {
      label: menuItems[3].label,
      subMenu: TripSubMenu,
    },
  ]

  return (
    <nav className="primary-nav show-for-medium">
      <h2 className="visuallyhidden">Main navigation</h2>
      <ul>
        {mainMenu.map((item, index) => (
          <li key={`menuItem_${index}`}>
            <a className={"primary-nav__subnav-toggle"} href="#">
              {item.label}
            </a>
            <item.subMenu locale={props.locale} />
          </li>
        ))}
      </ul>
    </nav>
  )
}

PrimaryMenu.defaultProps = defaultProps

export default PrimaryMenu
