/****************************************************

	Hook - Get all ATDW Products

****************************************************/
import { useStaticQuery, graphql } from "gatsby"

export const useAtdwProducts = () => {
  const { products } = useStaticQuery(
    graphql`
      query {
        products: allAtdwProducts {
          nodes {
            productId
            productserviceId
            status
            productName
            productCategoryId
            type
            subtypes
            tags
            intro
            region
            subregion
            path
            thumbnail
            nttags
            prices {
              priceTo
              priceFrom
            }
            dates {
              startDate
              endDate
            }
            location {
              latitude
              longitude
            }
            starRating
            langs {
              de_de {
                desc
                name
              }
              fr_fr {
                desc
                name
              }
              it_it {
                desc
                name
              }
              ja_jp {
                desc
                name
              }
              zh_chs {
                desc
                name
              }
              zh_cht {
                desc
                name
              }
            }
          }
        }
      }
    `
  )

  return products.nodes
}

export default useAtdwProducts
