import React from "react"

import Icon from "../../Icon"

interface Props {
  heading: string
  cssClass?: string
  arrowColor?: string
  children?: React.ReactElement | string
}

const defaultProps = {}

export const MobileMenuAccordion: React.FC<Props> = props => {
  return (
    <li>
      <a
        href="#"
        className={`mobile-nav__subnav-toggle ${props.cssClass || ""}`}
      >
        {props.heading}{" "}
        <svg viewBox="0 0 1792 1792" className="svg-icon icon-white">
          <use href="#angle-down"/>
        </svg>
      </a>
      <ul className="mobile-nav__subnav">{props.children}</ul>
    </li>
  )
}

MobileMenuAccordion.defaultProps = defaultProps

export default MobileMenuAccordion
