/****************************************************

  Utilities - Links

****************************************************/
import { PageModel, PageModels } from "../models/pages"
import { LinkModel, NavMenuModel, PageHierarchy } from "../models/nav"
import { ATDWItemModel, CmsAsset } from "../models/common"
import { BUILD_LANGS } from "./constants"
import { getFirstArrayItem } from "./utils_arrays"

/******************************************************
  Get Link
******************************************************/
export const getLink = (props: {
  url?: string
  asset?: CmsAsset
  entry?: PageModels
}) => {
  if (props.url) return { link: props.url, target: "_blank" }

  if (props.asset) return { link: props.asset.url, target: "_blank" }

  if (props.entry) return { link: getPrettyURL(props.entry), target: "_self" }

  return { link: "/", target: "_self" }
}

/******************************************************
  Build page hierarchy
******************************************************/
export const getPagePath = (page?: any): PageHierarchy[] => {
  if (page?.parent_page?.length)
    return [
      ...getPagePath(page.parent_page[0]),
      {
        id: page.uid,
        url: page.url,
        label: getNavLabel(page),
        short: page.heading,
      },
    ]
  return [{ id: page.id, url: page.url, label: getNavLabel(page) }]
}

const getNavLabel = (page?: PageModels) => {
  if (page) {
    if (page.url === "/") return "Home"
    return page.overrides?.menu_title || page.heading
  }
  return ""
}

/******************************************************
  Build page breadcrumbs
******************************************************/
export const buildBreadcrumbs = (page?: any): PageHierarchy[] => {
  if (page) {
    const pathParts = getPagePath(page)
    return pathParts.map((crumb, index) => {
      const previous = pathParts
        ?.slice(0, index + 1)
        ?.map(x => x.url)
        ?.join("/")
      return { ...crumb, url: getPrettyURL(previous) }
    })
  }
  return []
}

/******************************************************
  Build the page URL from page hierarchy
******************************************************/
export const getPageUrl = (page?: any): string => {
  if (page) {
    const langUrl = getLangUrl(
      page.publish_details ? page.publish_details.locale : page.locale
    )
    // console.log(langUrl + langUrl)

    return (
      langUrl +
      getPagePath(page)
        .map(x => x.url)
        .filter(Boolean)
        .join("")
        .replace("//", "/")
    ).replace(langUrl + langUrl, langUrl)
  }
  return ""
}

/******************************************************
  add language to URL
******************************************************/
export const getLangUrl = (locale: string = "en-au") => {
  let lang = ""

  if (locale && lang !== "en-au" && locale !== "") {
    lang = `${BUILD_LANGS[locale as keyof typeof BUILD_LANGS].url}`
  }
  return lang
}

/******************************************************
  make the URL pretty
  - add trailing slash
  - remove double slashes (but not protocol eg http://)
  - replace spaces with hyphens
  - sorry, we have  urls with double hyphens in them, *remove triple hyphens*
  - make lowercase
******************************************************/
export const getPrettyURL = (url: string | PageModels | undefined): string => {
  if (!url) return ""

  if (typeof url === "string") {
    // break out any query strings parameters
    let urlParts = url.split("?")
    const baseURL = urlParts.shift()
    const prettyUrl = (baseURL + "/")
      .replace(/(https?:\/\/)|(\/){2,}/g, "$1$2")
      .replace(/\ /g, "-")
      .toLowerCase()

    if (urlParts.length) return [prettyUrl, urlParts].join("?")
    return prettyUrl
  }

  return getPrettyURL(getPageUrl(url))
}

/******************************************************
  Resolve menu links and page names
******************************************************/
interface MenuLinksModel {
  menu: NavMenuModel
  pages: PageModel[]
  links: LinkModel[]
  articles: PageModel[]
  itineraries: PageModel[]
  menus: NavMenuModel[]
  locale?: string
}

export const resolveMenuLinks = ({
  menu,
  pages,
  links,
  articles = [],
  itineraries = [],
  menus = [],
  locale = "en-au",
}: MenuLinksModel): PageHierarchy[] => {
  return (
    menu?.items?.map((x: ATDWItemModel) => {
      if (x.type === "Menu") {
        const tmp = menus.find(
          y => y.uid === x.uid && y.publish_details?.locale === locale
        )
        if (tmp) {
          let label = tmp.heading
          let url = ""
          let uid = tmp.uid
          if (!label && tmp?.more_link.length) {
            const navItem = pages.find(
              page =>
                page.uid === tmp?.more_link[0].uid &&
                page.publish_details?.locale === locale
            )
            label = navItem?.overrides?.menu_title || navItem?.heading
            url = getPageUrl(navItem)
            uid = navItem?.uid
          }
          return {
            id: uid,
            label: label,
            url: url,
            nested: resolveMenuLinks({
              menu: tmp,
              pages,
              links,
              articles,
              itineraries,
              menus,
              locale,
            }),
          } as PageHierarchy
        }
      }

      if (x.type === "Link") {
        const link = links.find(
          link => link.uid === x.uid && link.publish_details?.locale === locale
        )
        if (link?.link?.url) {
          return {
            id: link?.uid,
            label: link?.visual?.text || "",
            url: link?.link?.url,
            thumbnail: link?.visual?.link_image?.url,
            thumbnail_alt:
              link?.visual?.link_image?.description || link?.visual?.text || "",
            new_window: link?.link?.new_window,
            link_class: link?.visual?.link_class,
            link_icon_svg_code: link?.visual?.link_icon_svg_code,
          }
        }
        if (link?.link?.asset?.url) {
          return {
            id: link?.uid,
            label: link?.visual?.text || "",
            url: link?.link?.asset?.url,
            thumbnail: link?.visual?.link_image?.url,
            thumbnail_alt:
              link?.visual?.link_image?.description || link?.visual?.text || "",
            new_window: link?.link?.new_window,
            link_class: link?.visual?.link_class,
            link_icon_svg_code: link?.visual?.link_icon_svg_code,
          }
        }
        const linkUid = getFirstArrayItem(link?.link?.item)?.uid || ""
        return resolveMenuLinks({
          menu: { items: link?.link?.item },
          pages,
          links,
          articles,
          itineraries,
          menus,
          locale,
        })[0]
      }

      if (x.type === "Article") {
        const article = articles.find(
          page => page.uid === x.uid && page.publish_details?.locale === locale
        )
        const image =
          getFirstArrayItem(article?.hero_image?.banners)?.banner?.image?.file
            ?.url || ""
        const imageAlt =
          getFirstArrayItem(article?.hero_image?.banners)?.banner?.image?.file
            ?.description ||
          article?.overrides?.menu_title ||
          article?.heading ||
          ""
        return {
          id: article?.uid,
          label: article?.overrides?.menu_title || article?.heading || "",
          url: getPageUrl(article),
          thumbnail: article?.overrides?.listing_image?.url || image,
          thumbnail_alt:
            article?.overrides?.listing_image?.description || imageAlt,
        }
      }

      if (x.type === "Itinerary") {
        const itinerary = itineraries.find(
          page => page.uid === x.uid && page.publish_details?.locale === locale
        )
        const image =
          getFirstArrayItem(itinerary?.hero_image?.banners)?.banner?.image?.file
            ?.url || ""
        const imageAlt =
          getFirstArrayItem(itinerary?.hero_image?.banners)?.banner?.image?.file
            ?.description ||
          itinerary?.overrides?.menu_title ||
          itinerary?.heading ||
          ""
        return {
          id: itinerary?.uid,
          label: itinerary?.overrides?.menu_title || itinerary?.heading || "",
          url: getPageUrl(itinerary),
          thumbnail: itinerary?.overrides?.listing_image?.url || image,
          thumbnail_alt:
            itinerary?.overrides?.listing_image?.description || imageAlt,
        }
      }

      const page = pages.find(
        page => page.uid === x.uid && page.publish_details?.locale === locale
      )
      const image =
        getFirstArrayItem(page?.hero_image?.banners)?.banner?.image?.file
          ?.url || ""
      const imageAlt =
        getFirstArrayItem(page?.hero_image?.banners)?.banner?.image?.file
          ?.description ||
        page?.overrides?.menu_title ||
        page?.heading ||
        ""
      return {
        id: page?.uid,
        label: page?.overrides?.menu_title || page?.heading || "",
        url: getPageUrl(page),
        thumbnail: page?.overrides?.listing_image?.url || image,
        thumbnail_alt: page?.overrides?.listing_image?.description || imageAlt,
      } as PageHierarchy
    }) || []
  )
}
