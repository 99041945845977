import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import axios from "axios"
import { getImageSrc } from "../../utils/utils_images"
import { useDictionary } from "../../hooks"

interface Props {
  locale: string
}

const defaultProps = {}

const KeepInTouch: React.FC<Props> = props => {
  if (!["en-au", "en-gb", "en-us", "en-sg"].includes(props.locale)) return null
  // graphQL
  const query = useStaticQuery(graphql`
    query {
      banner: contentstackConfig(code_id: { eq: "footerBanner" }) {
        ...ConfigFields
      }
    }
  `)
  const bannerImage = query.banner?.image
  const imageData = getSrc(bannerImage.gatsbyImageData)
  const imageSrcSet = [
    [640, 400],
    [768, 400],
    [1024, 500],
    [1284, 550],
    [1920, 700],
  ]
  return (
    <section className="keep-touch">
      {bannerImage && (
        <img
          className="keep-touch__bg lazyload"
          alt={bannerImage.description || bannerImage.title || ""}
          data-srcset={imageSrcSet
            .map(x => `${getImageSrc(imageData, x[0], x[1])} ${x[0]}w`)
            .join(", ")}
          data-src={getImageSrc(imageData, 1920, 700)}
        />
      )}

      <svg className="keep-touch__top svg--full-width" viewBox="0 0 1920 194">
        <path
          d="M545 4C327 4 101 194 0 194V0h1920v189c-80 0-414-103-539-103-155 0-257 14-402 1C806 71.5 700 4 545 4z"
          fill="#fff"
        />
      </svg>

      <div className="row keep-touch__content">
        <div></div>
        <div className="small-push-1 small-10 smedium-9 medium-7 large-6 xlarge-5 column">
          {/* heading */}
          <h2 className="keep-touch__heading">
            {useDictionary("Keep in touch - Heading", props.locale)}
          </h2>

          {/* content */}
          <p
            className="keep-touch__text"
            dangerouslySetInnerHTML={{
              __html: useDictionary(
                "Keep in touch - Description",
                props.locale
              ),
            }}
          ></p>
        </div>
        {/* form */}
        <form className="keep-touch__submit-form small-push-1 small-11 smedium-9 medium-8 large-6 xlarge-5 column">
          <div
            className="keep-touch__form"
            data-profile-endpoint="https://auth.northernterritory.com/"
            data-success-url=""
            data-modal-settings='{"width":600, "height":520, "viewport":767,"modal":1}'
            data-action="https://travelnt.createsend.com/t/t/s/ikjyht/"
          >
            <label
              id="keepEmailLabel"
              htmlFor="keepEmail"
              className="visuallyhidden"
            >
              {useDictionary(
                "Keep in touch - Email Address Visual Hidden Text",
                props.locale
              )}
            </label>
            <input
              id="keepEmail"
              name="cm-ikjyht-ikjyht"
              type="email"
              placeholder={useDictionary(
                "Keep in touch - Email Address",
                props.locale
              )}
              className="keep-touch__input keep-touch__input--email required"
              aria-labelledby="keepEmailLabel"
              required={false}
            />
            <input
              id="fieldjuhrii"
              name="cm-f-juhrii"
              type="hidden"
              value="northernterritory.com"
              className="keep-touch__input"
            />
            <button
              type="submit"
              name="keepsubmit"
              value="submit"
              className="button keep-submit keep-touch__submit"
            >
              {useDictionary("Keep in touch - Submit", props.locale)}
            </button>
          </div>
        </form>
        <div
          className="keep-touch__submit-success small-push-1 small-10 smedium-9 medium-7 large-6 xlarge-5 end column"
          style={{ display: "none" }}
        >
          <h2 className="keep-touch__heading">
            {useDictionary("Keep in touch - Thank you message", props.locale)}
          </h2>
          <p
            className="keep-touch__text"
            dangerouslySetInnerHTML={{
              __html: useDictionary(
                "Keep in touch - Success message",
                props.locale
              ),
            }}
          ></p>
        </div>
      </div>
    </section>
  )
}

KeepInTouch.defaultProps = defaultProps

export default KeepInTouch
