/****************************************************

	Hook - Get pages

****************************************************/
import { useStaticQuery, graphql } from "gatsby"
import { LinkModel } from "../models/nav"

export const useLinks = (locale: String) => {
  const { links } = useStaticQuery(
    graphql`
      query {
        links: allContentstackNtlink(
          filter: { title: { ne: "do not delete" } }
        ) {
          nodes {
            id
            link_id
            publish_details {
              locale
            }
            link {
              item
              new_window
              url
              asset {
                ...CmsImageFields
              }
            }
            uid
            visual {
              breadcrumb
              icon
              link_class
              link_icon_svg_code
              link_image {
                ...CmsImageFields
              }
              read_more_text
              short_description
              text
            }
          }
        }
      }
    `
  )
  return links.nodes
    .filter((x: any) => x.publish_details?.locale === locale)
    .map((link: LinkModel) => link)
}

export default useLinks
