/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react"

/****************************************************
    Site styles
****************************************************/
// import "./src/scss/app.scss"
import "./static/dist/app.css?v=20220808"

/****************************************************
    Redux
****************************************************/
import { Provider } from "react-redux"
import store from "./src/state/store"

// wrap app for global state context and redux
export const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
)

/****************************************************
  Page transition 
  - fix scroll jump on page transition
****************************************************/
export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const locationPath = location.pathname
  const prevLocationPath = prevRouterProps?.location?.pathname
  const savedPosition = getSavedScrollPosition(location) || [0, 0]

  // only scroll top if the page path has changed - not on query string update
  if (locationPath !== prevLocationPath) {
    window.scrollTo({
      top: savedPosition[1],
      left: savedPosition[0],
      behavior: "smooth",
    })
  }
  return false
}

/****************************************************
  Page change 
****************************************************/
export function onRouteUpdate({ location, prevLocation }) {
  // console.log("onRouteUpdate")
  ensureScript()
}
export function onPreRouteUpdate({ location, prevLocation }) {
  //document.location.href = location.pathname;
  ensureScript()
}
export function onInitialClientRender() {
  // console.log("onInitialClientRender")
//  ensureScript()
}
function ensureScript() {
  if (window.document && window.initJS) {
    
  } else {
   
    setTimeout(function () {
      ;(function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return
        }
        js = d.createElement(s)
        js.id = id
        js.onload = function () {
          window.initJS()
        }
        js.src = "/dist/app.js?v=4"
        fjs.parentNode.insertBefore(js, fjs)
      })(document, "script", "main-tnt-script")
    }, 500)
  }
}
