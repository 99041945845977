import React from "react"
import { Link } from "gatsby"

import {
  useArticles,
  useItineraries,
  useLinks,
  useMenus,
  usePages,
} from "../../../hooks"
import { getLangUrl, resolveMenuLinks } from "../../../utils/utils_links"
import { getImageSrc } from "../../../utils/utils_images"
import { NavMenuModel } from "../../../models/nav"

interface Props {
  locale: string
}

const defaultProps = {}

const TripSubMenu: React.FC<Props> = props => {
  //console.log("TRIP MENU: " + props.locale)
  const links = useLinks(props.locale)
  const pages = usePages(props.locale)
  const articles = useArticles(props.locale)
  const itineraries = useItineraries(props.locale)
  const menus = useMenus(props.locale)
  const langUrl = getLangUrl(props.locale)
  const menuLinkData = {
    pages: pages.filter((x: any) => x.publish_details?.locale === props.locale),
    links: links.filter((x: any) => x.publish_details?.locale === props.locale),
    articles: articles.filter(
      (x: any) => x.publish_details?.locale === props.locale
    ),
    itineraries: itineraries.filter(
      (x: any) => x.publish_details?.locale === props.locale
    ),
    menus: menus.filter((x: any) => x.publish_details?.locale === props.locale),
    locale: props.locale,
  }

  const subMenus: {
    items: any
    more: any
    image: any
    heading: any
    image_alt: any
  }[] = []
  const tripMenu: NavMenuModel = menus.find(
    (x: NavMenuModel) =>
      x.code_id === "menu-trip-ideas" &&
      x.publish_details?.locale === props.locale
  )
  if (!tripMenu) return null
  if (!tripMenu.items) return null
  for (let index = 0; index < tripMenu.items?.length; index++) {
    const slim_menu = tripMenu.items[index]
    const menu: NavMenuModel = menus.find(
      (x: NavMenuModel) =>
        x.uid === slim_menu.uid && x.publish_details?.locale === props.locale
    )
    if (!menu) break
    const menuItems = resolveMenuLinks({
      menu,
      ...menuLinkData,
    })
    const menuMore = resolveMenuLinks({
      menu: {
        items: menu?.more_link,
      },
      ...menuLinkData,
    })
    let menuImage, menuImageAlt
    if (menu.menu_image) {
      menuImage = menu.menu_image.url
      menuImageAlt = menu.menu_image.description || menu.heading
    }
    
    subMenus.push({
      items: menuItems,
      more: menuMore,
      image: menuImage,
      image_alt: menuImageAlt,
      heading: menu.heading,
    })
  }

  return (
    <div
      className="primary-nav__subnav primary-nav__subnav--image-headings image-headings--4col"
      id="trip-ideas"
    >
      <div
        className={`row align-justify ${
          tripMenu.layout === "3 column image heading layout"
            ? "medium-up-3"
            : "medium-up-2 large-up-4"
        }`}
      >
        {subMenus &&
          subMenus.map((subMenu, index) => (
            <div key={"submenu" + index} className="column">
              <div className="row">
                <div className="medium-9 column">
                  <span className="lazyload-wrapper ratio--3-2">
                    <img
                      className="lazyload"
                      data-src={getImageSrc(subMenu.image, 195, 130)}
                      alt={subMenu.image_alt}
                    />{" "}
                  </span>
                </div>
              </div>
              <h3
                dangerouslySetInnerHTML={{
                  __html: subMenu.heading || "",
                }}
              ></h3>
              <ul role="menu">
                <>
                  {subMenu.items &&
                    subMenu.items.map((item: any, i: number) => (
                      <li key={"submenu" + index + "item" + i}>
                        <Link
                          to={item.url}
                          dangerouslySetInnerHTML={{ __html: item.label || "" }}
                        ></Link>
                      </li>
                    ))}
                  {subMenu.more && (
                    <li>
                      <Link
                        to={subMenu.more[0].url.replace(
                          langUrl + langUrl,
                          langUrl
                        )}
                        className="button"
                        dangerouslySetInnerHTML={{
                          __html: subMenu.more[0].label || "",
                        }}
                      ></Link>
                    </li>
                  )}
                </>
              </ul>
            </div>
          ))}
      </div>
    </div>
  )
}

TripSubMenu.defaultProps = defaultProps

export default TripSubMenu
