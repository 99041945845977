/****************************************************

	Hook - Get itineraries

****************************************************/
import { useStaticQuery, graphql } from "gatsby"

export const useItineraries = (locale: String) => {
  const { itineraries } = useStaticQuery(
    graphql`
      query {
        itineraries: allContentstackItinerary(
          filter: { title: { ne: "do not delete" } }
        ) {
          nodes {
            ...ItineraryFields
          }
        }
      }
    `
  )
  return itineraries.nodes.filter((x: any) => x.publish_details?.locale === locale)
}

export default useItineraries
