import React from "react"
import { Link } from "gatsby"

import { useLinks, useMenus, usePages } from "../../../hooks"
import { getImageSrc } from "../../../utils/utils_images"
import { getLangUrl, resolveMenuLinks } from "../../../utils/utils_links"
import { NavMenuModel } from "../../../models/nav"

interface Props {
  locale: string
}

const defaultProps = {}

const PlanSubMenu: React.FC<Props> = props => {
  const links = useLinks(props.locale)
  const pages = usePages(props.locale)
  const menus = useMenus(props.locale)
  const langUrl = getLangUrl(props.locale)

  const menuLinkData = {
    pages: pages.filter((x: any) => x.publish_details?.locale === props.locale),
    links: links.filter((x: any) => x.publish_details?.locale === props.locale),
    articles: [],
    itineraries: [],
    menus: menus.filter((x: any) => x.publish_details?.locale === props.locale),
    locale: props.locale,
  }

  const menu: NavMenuModel = menus.find(
    (x: NavMenuModel) =>
      x.code_id === "menu-plan" && x.publish_details?.locale === props.locale
  )
  if (!menu) return null
  const items = resolveMenuLinks({
    menu: menu,
    ...menuLinkData,
  })
  const more = resolveMenuLinks({
    menu: {
      items: menu?.more_link,
    },
    ...menuLinkData,
  })

  return (
    <div
      className="primary-nav__subnav primary-nav__subnav--image-links"
      id="plan"
    >
      <ul
        className={"row medium-up-" + Math.ceil(items.length / 2)}
        role="menu"
      >
        {items &&
          items.map((item, i) => {
            return (
              <li key={`item_${i}`} className="column">
                <Link to={item.url} className="card__link">
                  {item.thumbnail && (
                    <span className="lazyload-wrapper ratio--3-2">
                      <img
                        className="lazyload"
                        data-src={getImageSrc(item.thumbnail, 270, 180)}
                        alt={item.thumbnail_alt || item.label}
                      />{" "}
                    </span>
                  )}
                  <h4
                    className="card__heading"
                    dangerouslySetInnerHTML={{ __html: item.label || "" }}
                  ></h4>
                </Link>
              </li>
            )
          })}
      </ul>
      {more && (
        <div className="row more-link">
          <div className="column">
            <Link
              to={more[0].url.replace(langUrl + langUrl, langUrl)}
              className="button"
              dangerouslySetInnerHTML={{ __html: more[0].label || "" }}
            ></Link>
          </div>
        </div>
      )}
    </div>
  )
}

PlanSubMenu.defaultProps = defaultProps

export default PlanSubMenu
