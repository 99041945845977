import React from "react"
import { Link } from "gatsby"

import MobileMenuAccordion from "./MobileMenuAccordion"

import {
  useArticles,
  useItineraries,
  useLinks,
  useMenus,
  usePages,
} from "../../../hooks"
import { getLangUrl, resolveMenuLinks } from "../../../utils/utils_links"
import { NavMenuModel, PageHierarchy } from "../../../models/nav"

interface Props {
  locale: string
}

const defaultProps = {}

const MobileMenu: React.FC<Props> = props => {
  const links = useLinks(props.locale || "en-au")
  const pages = usePages(props.locale || "en-au")
  const articles = useArticles(props.locale || "en-au")
  const itineraries = useItineraries(props.locale || "en-au")
  const menus = useMenus(props.locale || "en-au")

  const menuLinkData = {
    pages: pages,
    links: links,
    articles: articles,
    itineraries: itineraries,
    menus: menus,
    locale: props.locale,
  }

  const menu: NavMenuModel = menus.find(
    (x: NavMenuModel) => x.code_id === "menu-mobile"
  )
  const menuItems = resolveMenuLinks({ menu: menu, ...menuLinkData })
  if (!menuItems) return null

  return (
    <div id="mobile-nav-panel" className="mobile-nav__panel">
      <div className="mobile-nav__panel__content">
        <ul>
          {menuItems.map((menu, menuIndex) => (
            <MobMenuItem
              key={`menu_${menuIndex}`}
              {...menu}
              locale={props.locale}
            />
          ))}
          <li className="global-search">
            <form role="search" method="get" id="mobSearchform" >
              <div className="search-input">
                <label htmlFor="mobSearch" className="visuallyhidden">Search:</label>
                <input
                  id="mobSearch"
                  type="search"
                  name="keyword"
                  placeholder="Search"
                  className="global-search-input"
                />
                <div className="search-icon">
                  <label className="search-icon-label" title="Search">
                    <svg viewBox="0 0 20 20" fill="none" stroke="#1f1f5f" strokeWidth="3" className="svg-icon svg-search">
                      <use href="#search" />
                    </svg>
                    <input type="submit" value="search" />
                  </label>
                </div>
              </div>
            </form>
          </li>
        </ul>
      </div>
    </div>
  )
}

MobileMenu.defaultProps = defaultProps

export default MobileMenu

interface MobMenuItemProps extends PageHierarchy {
  locale: string
}

const MobMenuItem: React.FC<MobMenuItemProps> = props => {
  if (props.url)
    return (
      <li>
        <Link
          to={ props.url}
          dangerouslySetInnerHTML={{
            __html:
              (props.link_class === "map"
                ? `
                <svg class="svg-icon svg-map-with-pin" viewbox="0 0 31 36" fill="#fff">
                  <use href="#map-with-pin" />
                </svg>
                `
                : "") + props.label,
          }}
        ></Link>
      </li>
    )

  return (
    <MobileMenuAccordion heading={props.label || ""}>
      <>
        {props.nested?.map((menu, menuIndex) => (
          <MobMenuItem
            key={`menu_${menuIndex}`}
            {...menu}
            locale={props.locale}
          />
        ))}
      </>
    </MobileMenuAccordion>
  )
}
