import React, { useState } from "react"
import { Link } from "gatsby"
import { getLangUrl } from "../../../utils/utils_links"

interface Props {
  locale: string
}

const defaultProps = {}

const SecondaryMenu: React.FC<Props> = props => {
  // state
  const [search, setSearch] = useState("")

  // event handlers
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value)
  }

  const handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (search) {
      window.location.replace(
        `${getLangUrl(props.locale)}/search-results/?keyword=${search}`
      )
      setSearch("")
    }
  }

  return (
    <nav className="secondary-nav">
      <ul>
        {/* Search */}
        <li className="global-search show-for-medium">
          <form
            role="search"
            method="get"
            id="deskSearchform"
            onSubmit={handleSubmit}
          >
            <div className="search-input">
              <label htmlFor="deskSearch" className="visuallyhidden">
                Search:
              </label>
              <input
                id="deskSearch"
                type="search"
                value={search}
                name="keyword"
                placeholder="Search"
                className="global-search-input"
                onChange={handleChangeSearch}
              />
              <div className="search-icon">
                <label className="search-icon-label" title="Search">
                  <svg viewBox="0 0 20 20" fill="none" stroke="#fff" strokeWidth="3" className="svg-icon svg-search">
                    <use href="#search" />
                  </svg>
                  <input type="submit" value="search" />
                </label>
              </div>
            </div>
          </form>
        </li>

        {/* Map */}
        <li className="map show-for-medium">
          <Link
            to={getLangUrl(props.locale) + "/map"}
            className="global-view-map"
          >
            <svg className="svg-icon svg-map-with-pin" viewBox="0 0 31 36" fill="#fff">
              <use href="#map-with-pin" />
            </svg>
            <span>Map</span>
          </Link>
        </li>

        {/* Wishlist */}
        <li className="wishlist">
          <Link to={getLangUrl(props.locale) + "/my-trip"}>
            <div className="notification-wrapper">
              <svg className="svg-heart-icon svg-icon" viewBox="0 0 20 20">
                <use href="#heart" />
              </svg>
              <i className="notification-badge" style={{ display: "none" }}>
                0
              </i>
            </div>
            <span>My trip</span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

SecondaryMenu.defaultProps = defaultProps

export default SecondaryMenu
