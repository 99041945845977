/****************************************************

	Hook - Get pages

****************************************************/
import { useStaticQuery, graphql } from "gatsby"

export const usePagesAU = (locale: String) => {
  const { pages } = useStaticQuery(
    graphql`
      query {
        pages: allContentstackPage(
          filter: {
            title: { ne: "do not delete" }
            publish_details: { locale: { eq: "en-au" } }
          }
        ) {
          nodes {
            ...PageFields
          }
        }
      }
    `
  )
  return pages.nodes.filter((x: any) => x.publish_details?.locale === locale)
}

export default usePagesAU
