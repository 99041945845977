/****************************************************

	Hook - Get pages

****************************************************/
import { useStaticQuery, graphql } from "gatsby"

export const useMenus = (locale: String) => {
  const { menus } = useStaticQuery(
    graphql`
      query {
        menus: allContentstackMenu(
          filter: { title: { ne: "do not delete" } }
        ) {
          nodes {
            ...MenuFields
          }
        }
      }
    `
  )
  return menus.nodes.filter((x: any) => x.publish_details?.locale === locale)
}

export default useMenus
