/****************************************************

	Hook - Get pages

****************************************************/
import { useStaticQuery, graphql } from "gatsby"
import usePagesAU from "./usePages/usePagesAU"
import usePagesUS from "./usePages/usePagesUS"
import usePagesGB from "./usePages/usePagesGB"
import usePagesSG from "./usePages/usePagesSG"
import usePagesCHT from "./usePages/usePagesCHT"
import usePagesCHS from "./usePages/usePagesCHS"
import usePagesJP from "./usePages/usePagesJP"
import usePagesDE from "./usePages/usePagesDE"
import usePagesIT from "./usePages/usePagesIT"
import usePagesFR from "./usePages/usePagesFR"

export const usePages = (locale: String) => {
  // switch on locale from this list "en-au,en-us,en-gb,en-sg,fr-fr,it-it,de-de,ja-jp,zh-chs,zh-cht" and return usePagesAU from usePages folder
  
  
  switch (locale||"en-au") {
    case "en-au":
      return usePagesAU(locale)
    case "en-us":
      return usePagesUS(locale)
    case "en-gb":
      return usePagesGB(locale)
    case "en-sg":
      return usePagesSG(locale)
    case "fr-fr":
      return usePagesFR(locale)
    case "it-it":
      return usePagesIT(locale)
    case "de-de":
      return usePagesDE(locale)
    case "ja-jp":
      return usePagesJP(locale)
    case "zh-chs":
      return usePagesCHS(locale)
    case "zh-cht":
      return usePagesCHT(locale)
  }
}

export default usePages
