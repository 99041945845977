import React from "react"

import { useLinks, useMenus, usePages } from "../../../hooks"
import { getLangUrl, resolveMenuLinks } from "../../../utils/utils_links"
import { NavMenuModel } from "../../../models/nav"

interface Props {
  locale: string
}

const defaultProps = {}

const PlacesSubMenu: React.FC<Props> = props => {
  const links = useLinks(props.locale)
  const pages = usePages(props.locale)
  const menus = useMenus(props.locale)
  const langUrl = getLangUrl(
    props.locale
  )

  const menuLinkData = {
    pages: pages.filter((x: any) => x.publish_details?.locale === props.locale),
    links: links.filter((x: any) => x.publish_details?.locale === props.locale),
    articles: [],
    itineraries: [],
    menus: menus.filter((x: any) => x.publish_details?.locale === props.locale),
    locale: props.locale,
  }

  const popular: NavMenuModel = menus.find(
    (x: NavMenuModel) =>
      x.code_id === "menu-most-popular-places" &&
      x.publish_details?.locale === props.locale
  )
  const popularItems = resolveMenuLinks({
    menu: popular,
    ...menuLinkData,
  })

  const popularMoreLink = resolveMenuLinks({
    menu: {
      items: popular?.more_link,
    },
    ...menuLinkData,
  })

  const explore = menus.find(
    (x: NavMenuModel) =>
      x.code_id === "menu-explore-by-region" &&
      x.publish_details?.locale === props.locale
  )

  const exploreItems = resolveMenuLinks({
    menu: explore,
    ...menuLinkData,
  })

  if (!popularItems) return null
  if (!exploreItems) return null
  if (!popular) return null

  return (
    <div
      className="primary-nav__subnav primary-nav__subnav--places"
      id="places-to-go"
    >
      <div className="row">
        <div className="show-for-xlarge xlarge-3 xlarge-push-6 column">
          {/* Map */}
          <div className="svg-container ratio--region-map">
            <svg
              id="navmap-regions"
              viewBox="0 0 129 216"
              width="52.04"
              height="87.43"
              style={{ overflow: "visible" }}
            >
              <svg
                x="-282"
                y="-71"
                width="703"
                height="660"
                viewBox="0 0 270 270"
                opacity="0.15"
              >
                <g
                  id="navmap-aus"
                  stroke="#1f1f5f"
                  fill="none"
                  strokeWidth="1.4"
                >
                  <use href="#regionmap-aus"/>
                </g>
              </svg>
              <g id="navmap-strokes" fill="#FFFFFF">
                <use id="#regionmap-strokes" href="#regionmap-strokes"/>{" "}
              </g>
              <g id="navmap-darwin" fill="#05A0BD">
                <use href="#regionmap-darwin"/>
              </g>
              <g id="navmap-katherine" fill="#7D7737">
                <use href="#regionmap-katherine"/>
              </g>
              <g id="navmap-tennant" fill="#D77F00">
                <use href="#regionmap-tennant"/>
              </g>
              <g id="navmap-kakadu" fill="#7D9F1F">
                <use href="#regionmap-kakadu"/>
              </g>
              <g id="navmap-arnhem" fill="#4B764D">
                <use href="#regionmap-arnhem"/>
              </g>
              <g id="navmap-alice" fill="#CB3E0B">
                <use href="#regionmap-alice"/>
              </g>
              <g id="navmap-uluru" fill="#9D0E13">
                <use href="#regionmap-uluru"/>
              </g>
              <svg
                id="navmap-pin"
                x="95.44"
                y="-13.09"
                width="21"
                height="30"
                viewBox="0 0 7 10"
                style={{ overflow: "visible", display: "none" }}
              >
                <ellipse
                  fill="#FFF"
                  cx="3.5"
                  cy="9.5"
                  rx=".6"
                  ry=".3"
                  className="pin-pulse"
                  data-svg-origin="3.5 9.5"
                  transform="matrix(9,0,0,9,-28,-76)"
                  style={{ transformOrigin: "0px 0px 0px", opacity: 0 }}
                ></ellipse>
                <use href="#map-pin"/>
              </svg>
            </svg>
          </div>
        </div>
        {/* Places */}
        <div className="medium-8 xlarge-6 xlarge-pull-3 column">
          <h3>{popular.heading}</h3>
          <ul className="primary-nav__featured-places" role="menu">
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/darwin-and-surrounds/destinations/darwin"
                }
                data-pin-x="16.59"
                data-pin-y="-9.25"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith("/darwin-and-surrounds/destinations/darwin")
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/alice-springs-and-surrounds/destinations/alice-springs"
                }
                data-pin-x="56.98"
                data-pin-y="147.75"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith(
                      "/alice-springs-and-surrounds/destinations/alice-springs"
                    )
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/uluru-and-surrounds/destinations/uluru"
                }
                data-pin-x="18.53"
                data-pin-y="170.27"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith("/uluru-and-surrounds/destinations/uluru")
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/kakadu-and-surrounds/destinations/kakadu-national-park"
                }
                data-pin-x="38.38"
                data-pin-y="-6.38"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith(
                      "/kakadu-and-surrounds/destinations/kakadu-national-park"
                    )
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/darwin-and-surrounds/destinations/litchfield-national-park"
                }
                data-pin-x="16.37"
                data-pin-y="-3.74"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith(
                      "/darwin-and-surrounds/destinations/litchfield-national-park"
                    )
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/uluru-and-surrounds/destinations/watarrka-national-park"
                }
                data-pin-x="26.26"
                data-pin-y="155.75"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith(
                      "/uluru-and-surrounds/destinations/watarrka-national-park"
                    )
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/darwin-and-surrounds/destinations/tiwi-islands"
                }
                data-pin-x="13.93"
                data-pin-y="-18.84"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith(
                      "/darwin-and-surrounds/destinations/tiwi-islands"
                    )
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/arnhem-land/destinations/east-arnhem-land"
                }
                data-pin-x="95.44"
                data-pin-y="-13.09"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith("/arnhem-land/destinations/east-arnhem-land")
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/katherine-and-surrounds/destinations/mataranka-thermal-pool"
                }
                data-pin-x="47"
                data-pin-y="24.28"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith(
                      "/katherine-and-surrounds/destinations/mataranka-thermal-pool"
                    )
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/katherine-and-surrounds/destinations/nitmiluk-national-park"
                }
                data-pin-x="37.63"
                data-pin-y="16.04"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith(
                      "/katherine-and-surrounds/destinations/nitmiluk-national-park"
                    )
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/tennant-creek-and-barkly-region/destinations/karlu-karlu--devils-marbles-conservation-reserve"
                }
                data-pin-x="61.98"
                data-pin-y="102.89"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith(
                      "/tennant-creek-and-barkly-region/destinations/karlu-karlu--devils-marbles-conservation-reserve"
                    )
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/kakadu-and-surrounds/destinations/maguk"
                }
                data-pin-x="38.82"
                data-pin-y="0.55"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith("/kakadu-and-surrounds/destinations/maguk")
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) +
                  "/alice-springs-and-surrounds/destinations/tjoritja--west-macdonnell-national-park"
                }
                data-pin-x="54.98"
                data-pin-y="148.04"
                target=""
              >
                {
                  popularItems.find(x =>
                    x.url.endsWith(
                      "/alice-springs-and-surrounds/destinations/tjoritja--west-macdonnell-national-park"
                    )
                  )?.label
                }
              </a>
            </li>
            {popularMoreLink && (
              <li>
                <a href={popularMoreLink[0].url.replace(langUrl+langUrl, langUrl)} className="button">
                  {popularMoreLink[0].label}
                </a>
              </li>
            )}
          </ul>
        </div>

        {/* Regions */}
        <div className="medium-4 xlarge-3 column">
          <h3>{explore.heading}</h3>
          <ul className="primary-nav__regions" role="menu">
            <li>
              <a
                href={getLangUrl(props.locale) + "/darwin-and-surrounds"}
                data-pin-x="16.7"
                data-pin-y="-9.28"
                target=""
              >
                {
                  exploreItems.find(x =>
                    x.url.endsWith("/darwin-and-surrounds")
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={getLangUrl(props.locale) + "/alice-springs-and-surrounds"}
                data-pin-x="56.94"
                data-pin-y="147.74"
                target=""
              >
                {
                  exploreItems.find(x =>
                    x.url.endsWith("/alice-springs-and-surrounds")
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={getLangUrl(props.locale) + "/uluru-and-surrounds"}
                data-pin-x="19.1"
                data-pin-y="171.88"
                target=""
              >
                {
                  exploreItems.find(x => x.url.endsWith("/uluru-and-surrounds"))
                    ?.label
                }
              </a>
            </li>
            <li>
              <a
                href={getLangUrl(props.locale) + "/kakadu-and-surrounds"}
                data-pin-x="38.38"
                data-pin-y="-6.38"
                target=""
              >
                {
                  exploreItems.find(x =>
                    x.url.endsWith("/kakadu-and-surrounds")
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={getLangUrl(props.locale) + "/katherine-and-surrounds"}
                data-pin-x="35.6"
                data-pin-y="17.84"
                target=""
              >
                {
                  exploreItems.find(x =>
                    x.url.endsWith("/katherine-and-surrounds")
                  )?.label
                }
              </a>
            </li>
            <li>
              <a
                href={getLangUrl(props.locale) + "/arnhem-land"}
                data-pin-x="68.07"
                data-pin-y="1.79"
                target=""
              >
                {exploreItems.find(x => x.url.endsWith("/arnhem-land"))?.label}
              </a>
            </li>
            <li>
              <a
                href={
                  getLangUrl(props.locale) + "/tennant-creek-and-barkly-region"
                }
                data-pin-x="61.04"
                data-pin-y="89.94"
                target=""
              >
                {
                  exploreItems.find(x =>
                    x.url.endsWith("/tennant-creek-and-barkly-region")
                  )?.label
                }
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

PlacesSubMenu.defaultProps = defaultProps

export default PlacesSubMenu
