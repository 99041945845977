import React from "react"
import { Link } from "gatsby"

import Icon from "../../Icon"

import { useDictionary, useLinks, useMenus, usePages } from "../../../hooks"
import { getLangUrl, resolveMenuLinks } from "../../../utils/utils_links"
import { NavMenuModel, PageHierarchy } from "../../../models/nav"

interface Props {
  locale?: string
  path: string
}

const defaultProps = {
  locale: "en-au",
}

const FooterContent: React.FC<Props> = props => {
  const links = useLinks(props.locale || "en-au")
  const pages = usePages(props.locale || "en-au")
  const menus = useMenus(props.locale || "en-au")

  const menuLinkData = {
    pages: pages,
    links: links,
    articles: [],
    itineraries: [],
    menus: menus,
    locale: props.locale,
  }

  const subMenus: { items: any; heading: any }[] = []
  const planMenu: NavMenuModel = menus.find(
    (x: NavMenuModel) =>
      x.code_id === "menu-footer-plan" &&
      x.publish_details?.locale === props.locale
  )
  if (!planMenu) return null
  const planMenuItems = resolveMenuLinks({
    menu: planMenu,
    ...menuLinkData,
  })
  subMenus.push({
    items: planMenuItems,
    heading: planMenu.heading,
  })

  const findMenu: NavMenuModel = menus.find(
    (x: NavMenuModel) =>
      x.code_id === "menu-footer-find-out-more" &&
      x.publish_details?.locale === props.locale
  )
  if (!findMenu) return null
  const findMenuItems = resolveMenuLinks({
    menu: findMenu,
    ...menuLinkData,
  })
  subMenus.push({
    items: findMenuItems,
    heading: findMenu.heading,
  })

  const otherMenu: NavMenuModel = menus.find(
    (x: NavMenuModel) =>
      x.code_id === "menu-footer-other-sites" &&
      x.publish_details?.locale === props.locale
  )
  if (!otherMenu) return null
  const otherMenuItems = resolveMenuLinks({
    menu: otherMenu,
    ...menuLinkData,
  })
  subMenus.push({
    items: otherMenuItems,
    heading: otherMenu.heading,
  })

  const followMenu: NavMenuModel = menus.find(
    (x: NavMenuModel) =>
      x.code_id === "menu-footer-follow-us" &&
      x.publish_details?.locale === props.locale
  )
  if (!followMenu) return null
  const followMenuItems = resolveMenuLinks({
    menu: followMenu,
    ...menuLinkData,
  })
  const pagePath = props.path.replace(getLangUrl(props.locale), "")
  return (
    <>
      {/* Menus */}
      <div className="row">
        {subMenus.map((column, col_index) => (
          <nav
            key={`footerCol_${col_index}`}
            className="small-6 smedium-push-1 smedium-5 medium-4 medium-push-0 xlarge-3 column"
          >
            <h4>{column.heading}</h4>
            <ul className="footer__nav">
              {column.items.map((item: PageHierarchy, item_index: number) => (
                <li key={`footerItem_${col_index}_${item_index}`}>
                  {item.new_window && (
                    <a href={item.url} target="_blank" rel="noopener">
                      {item.label}
                    </a>
                  )}

                  {!item.new_window && <Link to={item.url}>{item.label}</Link>}
                </li>
              ))}
            </ul>
          </nav>
        ))}

        {/* Social Links */}
        <nav className="small-11 small-centered xlarge-3 xlarge-uncentered column">
          <h4 className="show-for-xlarge">{followMenu.heading}</h4>
          <ul className="footer__social">
            {followMenuItems &&
              followMenuItems.map((item, col_index) => (
                <li key={`followCol_${col_index}`}>
                  {item.link_icon_svg_code && (
                    <a
                      href={item.url}
                      className="footer__social__link"
                      target={item.new_window ? "_blank" : ""}
                      rel="noopener"
                      title={item.label}
                      dangerouslySetInnerHTML={{
                        __html: item.link_icon_svg_code || "",
                      }}
                    ></a>
                  )}
                  {!item.link_icon_svg_code && (
                    <a
                      href={item.url}
                      className="footer__social__link"
                      target={item.new_window ? "_blank" : ""}
                      rel="noopener"
                    >
                      <span className="show-for-sr">{item.label}</span>
                      <i className={item.link_class} />
                    </a>
                  )}
                </li>
              ))}
          </ul>
        </nav>
      </div>

      <div className="row">
        <div className="footer__aoc small-centered smedium-10 large-uncentered large-7 xlarge-6 column">
          <h3 className="show-for-large">
            {useDictionary(
              "Global copy settings - Acknowledgement heading",
              props.locale
            )}
          </h3>
          <div className="footer__aoc__content">
            <div className="footer__aoc__text">
              <div
                dangerouslySetInnerHTML={{
                  __html: useDictionary(
                    "Global copy settings - Acknowledgement text",
                    props.locale
                  ),
                }}
              ></div>

              <a
                href={
                  getLangUrl(props.locale) +
                  "/things-to-do/art-and-culture/aboriginal-culture"
                }
              >
                {useDictionary(
                  "Global copy settings - Acknowledgement link",
                  props.locale
                )}
                <svg viewBox="0 0 1792 1792" className="svg-icon icon-white">
                  <use href="#angle-down" transform="rotate(270 896 896)" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <nav className="footer__language-selector small-centered small-10 smedium-7 medium-5 large-uncentered large-4 xlarge-3 column">
          <h2 className="visuallyhidden">
            {useDictionary(
              "Global copy settings - Language selector heading",
              props.locale
            )}
          </h2>
          <button
            className="tnt-disclosure__button close-when-clicked-outside"
            aria-expanded="false"
          >
            <svg viewBox="0 0 2000 2000" fill="#fff" className="svg-globe">
              <path d="M890 1969c-386-44-717-322-824-691-54-188-54-368 0-556 90-310 346-566 657-656 348-101 715-7 967 247 333 338 379 848 111 1242-156 230-415 382-704 414-106 12-100 12-207 0zm-126-161c-55-62-140-189-180-270-16-32-32-58-36-58-13 0-160 76-183 94-19 16-18 18 45 77 36 34 96 79 134 102 65 38 226 106 254 107 8 0-8-24-34-52zm186-184v-236l-72 7c-72 7-214 38-224 48-9 9 32 93 89 180 45 68 189 237 203 237 2 0 4-106 4-236zm307-1c57-87 98-171 89-180-10-10-152-41-224-48l-72-7v238l1 239 76-80c42-44 101-117 130-162zm44 203c115-43 210-101 289-175 63-59 64-61 45-77-23-18-170-94-183-94-4 0-20 26-36 58-40 81-125 208-180 270-26 28-42 52-34 52 7-1 52-15 99-34zm-838-413c50-22 58-29 52-47-16-56-45-198-51-253l-7-63H118l7 53c15 121 66 267 125 361l30 47 63-36c34-20 88-48 120-62zm1321-16c46-94 79-201 91-294l7-53h-339l-7 63c-7 55-35 197-51 253-6 18 1 24 43 42 28 12 81 40 118 61 49 28 72 37 81 30 6-6 32-52 57-102zm-1029-78c50-10 114-19 143-19h52v-250H560v44c0 50 14 128 36 204 14 51 16 54 42 47 15-4 68-16 117-26zm650-23c19-66 35-159 35-208v-38h-390v247l83 6c45 4 116 15 157 26 41 10 80 19 87 20s19-23 28-53zM464 883c7-66 38-221 51-254 4-11-9-22-52-41-32-15-86-43-120-63l-62-36-27 43c-62 98-114 245-129 366l-7 52h339l7-67zm484-55-3-123-77-7c-42-4-116-16-164-28-48-11-91-17-95-13-14 15-40 134-46 213l-6 80h394l-3-122zm489 42c-6-79-32-198-46-213-4-4-47 2-95 13-48 12-122 24-164 28l-77 7-3 123-3 122h394l-6-80zm438 28c-15-121-67-268-129-366l-27-43-62 36c-34 20-88 48-120 62-44 20-56 30-52 42 13 33 44 188 51 254l7 67h339l-7-52zM950 368l-1-243-75 78C750 334 626 539 660 560c21 13 196 46 258 49l32 1V368zm280 221c117-25 122-28 115-57-18-72-149-264-244-357l-51-49v486l68-7c37-3 87-11 112-16zM578 467c28-66 103-184 165-260 56-67 48-71-61-28-103 41-196 99-272 169-49 45-62 63-54 72 14 14 175 99 189 100 6 0 20-24 33-53zm960 14c130-66 128-61 52-132-75-71-168-129-272-170-109-43-117-39-61 29 62 75 137 193 165 259 13 29 26 53 31 53 4 0 42-18 85-39z" />
            </svg>
            <span className="tnt-disclosure__label">English</span>
            <svg
              viewBox="0 0 1792 1792"
              className="svg-icon svg-angle-down"
              fill="#fff"
            >
              <use href="#angle-down" />
            </svg>
          </button>
          <div role="group">
            <ul>
              <li>
                <a rel="alternate" href={pagePath===""?"/":pagePath} hrefLang="en" lang="en">
                  English
                </a>
              </li>
              <li>
                <a
                  rel="alternate"
                  href={"/gb/en" + pagePath}
                  hrefLang="en-gb"
                  lang="en-gb"
                >
                  English (UK)
                </a>
              </li>
              <li>
                <a
                  rel="alternate"
                  href={"/us/en" + pagePath}
                  hrefLang="en-us"
                  lang="en-us"
                >
                  English (US)
                </a>
              </li>
              <li>
                <a
                  rel="alternate"
                  href={"/sg/en" + pagePath}
                  hrefLang="en-sg"
                  lang="en-sg"
                >
                  English (Singapore)
                </a>
              </li>
              <li>
                <a
                  rel="alternate"
                  href={"/fr/fr" + pagePath}
                  hrefLang="fr"
                  lang="fr"
                >
                  Fran&#231;ais
                </a>
              </li>
              <li>
                <a
                  rel="alternate"
                  href={"/it/it" + pagePath}
                  hrefLang="it"
                  lang="it"
                >
                  Italiano
                </a>
              </li>
              <li>
                <a
                  rel="alternate"
                  href={"/de/de" + pagePath}
                  hrefLang="de"
                  lang="de"
                >
                  Deutsch
                </a>
              </li>
              <li>
                <a
                  rel="alternate"
                  href={"/jp/ja" + pagePath}
                  hrefLang="ja"
                  lang="ja"
                >
                  日本語
                </a>
              </li>
              <li>
                <a
                  rel="alternate"
                  href={"/cn/zh" + pagePath}
                  hrefLang="zh-Hans"
                  lang="zh-Hans"
                >
                  简体中文
                </a>
              </li>
              <li>
                <a
                  rel="alternate"
                  href={"/tw/zh" + pagePath}
                  hrefLang="zh-Hant"
                  lang="zh-Hant"
                >
                  繁體中文
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  )
}

FooterContent.defaultProps = defaultProps

export default FooterContent
