/****************************************************

	Hook - Get dictionary translation

****************************************************/
import { useStaticQuery, graphql } from "gatsby"

import { Dictionary } from "../models/common"
import dictionaryItems from "../json/dictionary-items.json"

export const useDictionary = (term: string, locale: string = "en-au") => {
  const query = useStaticQuery(
    graphql`
      query {
        allContentstackDictionary {
          nodes {
            term
            translation
            locale
          }
        }
      }
    `
  )

  // check the dictionary from CS for "term"
  let word = query.allDictionary?.nodes?.find(
    (x: Dictionary) => x.term === term && x.locale === locale
  ).translation  
  if (!word) {
    const item = dictionaryItems.find(x => x.key === term)
    if (item) {
      word = item.value[locale as keyof typeof item.value]
    }
    
  }
  // return translation or original term
  return word || term
}

export default useDictionary
