/****************************************************

	Hook - Get all articles

****************************************************/
import { useStaticQuery, graphql } from "gatsby"

export const useArticles = (locale: String) => {
  const { articles } = useStaticQuery(
    graphql`
      query {
        articles: allContentstackArticle(
          filter: { title: { ne: "do not delete" } }
        ) {
          nodes {
            ...ArticleFields
          }
        }
      }
    `
  )
  return articles.nodes.filter((x: any) => x.publish_details?.locale === locale)
}

export default useArticles
