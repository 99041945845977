import React from "react"
import { Link } from "gatsby"

import { useSelector } from "react-redux"
import { siteSelector } from "../../../state/site"

import Icon from "../../Icon"
import { getLangUrl } from "../../../utils/utils_links"
import { useDictionary } from "../../../hooks"

interface Props {
  locale: string
}

const defaultProps = {}

const FooterBreadcrumbs: React.FC<Props> = props => {
  // redux
  const site = useSelector(siteSelector)

  if (!site.breadcrumbs) return null
  return (
    <div className="row">
      <div className="breadcrumbs smedium-push-1 medium-push-0 column">
        <h2 className="breadcrumbs__heading show-for-large">
          {useDictionary(
            "Global copy settings - Breadcrumb heading",
            props.locale
          )}
        </h2>
        <ul
          className="breadcrumbs__list"
          itemScope={false}
          itemType="http://schema.org/BreadcrumbList"
        >
          <li
            className="breadcrumbs__item"
            itemScope={false}
            itemProp="itemListElement"
            itemType="http://schema.org/ListItem"
          >
            <Link to={getLangUrl(props.locale) + "/"} itemProp="item">
              <span>Home</span>
            </Link>
            <meta itemProp="position" content="1" />
            <svg viewBox="0 0 1792 1792" className="svg-icon icon-white">
              <use href="#angle-down" transform="rotate(270 896 896)"/>
            </svg>
          </li>

          {site.breadcrumbs
            .filter(x => x.url !== "/")
            .map((page, index) => (
              <li
                key={"breadcrumb_" + index}
                className="breadcrumbs__item"
                itemScope={false}
                itemProp="itemListElement"
                itemType="http://schema.org/ListItem"
              >
                <Link to={getLangUrl(props.locale) + page.url} itemProp="item">
                  <span>{(page.label || "").replace(/\&amp;/g, "&")}</span>
                </Link>
                <meta itemProp="position" content={index + 2 + ""} />
                <svg viewBox="0 0 1792 1792" className="svg-icon icon-white">
                  <use href="#angle-down" transform="rotate(270 896 896)"/>
                </svg>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

FooterBreadcrumbs.defaultProps = defaultProps

export default FooterBreadcrumbs
